import { ReactElement } from 'react'
import styled from '@emotion/styled'
import { Modal } from '@mui/material'
import { bgColor } from '@/utils/themeConfigs/customTheme'

type wrapperWidthSize = 'small' | 'sm' | 'ml' | 'large' | 'extraLarge'
type innerWidthSize = 'small' | 'sm' | 'ml' | 'large' | 'extraLarge'

type BasicModalProps = {
  title?: string
  subTitle?: string
  children?: ReactElement
  open: boolean
  closeButton?: boolean
  wrapperWidth?: wrapperWidthSize
  innerWidth?: innerWidthSize
  onClose: () => void
}

const modalWrapperWidth: {
  [key in wrapperWidthSize]: string
} = {
  small: '390px',
  sm: '438px',
  ml: '480px',
  large: '640px',
  extraLarge: '820px',
}

const CustomMuiModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalWrapper = styled('div')<{ wrapperWidth: wrapperWidthSize }>`
  position: relative;
  background-color: ${bgColor.white};
  border-radius: 6px;
  overflow-y: auto;
  max-height: 90vh;
  margin: auto 16px;
  max-width: 100%;
  width: ${({ wrapperWidth }) => modalWrapperWidth[wrapperWidth]};
`

const ModalInner = styled('div')<{ innerWidth: innerWidthSize }>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  padding: 24px;
  max-width: 100%;
  width: ${({ innerWidth }) => modalWrapperWidth[innerWidth]};
`

const ModalTitle = styled('h3')`
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 600;
`

const ModalSubTitle = styled('p')`
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.448;
  text-align: center;
`

export const BasicModal = ({
  title,
  subTitle,
  children,
  open = false,
  wrapperWidth = 'sm',
  innerWidth = 'sm',
  onClose,
}: BasicModalProps) => {
  return (
    <CustomMuiModal open={open} onClose={onClose}>
      <ModalWrapper wrapperWidth={wrapperWidth}>
        <ModalInner innerWidth={innerWidth}>
          {title && <ModalTitle>{title}</ModalTitle>}
          {subTitle && <ModalSubTitle>{subTitle}</ModalSubTitle>}
          {children}
        </ModalInner>
      </ModalWrapper>
    </CustomMuiModal>
  )
}
