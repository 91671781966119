export const ChatIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
    >
      <path
        d='M13.995 21.2593C16.5449 21.2593 18.8576 20.2018 20.4982 18.5019C20.7749 18.2153 20.7453 17.7606 20.429 17.5234C20.429 17.5234 16.4362 16.3671 13.6787 12.562'
        stroke='white'
        strokeWidth='2.03046'
        strokeMiterlimit='10'
      />
      <path
        d='M12.5518 11.791C17.2167 13.0067 22.5043 12.2358 22.5043 12.2358C22.781 12.2358 23.0182 11.9986 22.9984 11.7218C22.7316 6.97783 18.798 3.20239 13.9848 3.20239'
        stroke='white'
        strokeWidth='2.03046'
        strokeMiterlimit='10'
      />
      <path
        d='M18.3963 8.5748C18.437 7.70241 17.9573 6.97128 17.3248 6.94178C16.6923 6.91229 16.1466 7.59559 16.1059 8.46799C16.0652 9.34039 16.545 10.0715 17.1774 10.101C17.8099 10.1305 18.3557 9.4472 18.3963 8.5748Z'
        fill='white'
      />
      <path
        d='M12.3426 14.6375C10.445 15.0032 8.80439 13.3626 9.16019 11.4551C9.35786 10.3976 10.2276 9.53775 11.2851 9.3302C13.1827 8.96452 14.8233 10.6051 14.4675 12.5126C14.2699 13.5701 13.4001 14.43 12.3426 14.6375Z'
        fill='white'
      />
      <path
        d='M11.6318 11.7813C11.4835 11.7813 11.3353 11.6923 11.276 11.5441L8.91384 6.00939C8.26154 4.33911 8.55804 2.89614 9.72428 1.93746C10.9498 0.929355 12.7189 0.939239 13.9247 1.96711C15.081 2.94556 15.3578 4.38852 14.6857 6.04892L12.541 11.5539C12.462 11.7516 12.2346 11.8406 12.037 11.7615C11.8393 11.6824 11.7504 11.4551 11.8294 11.2574L13.9741 5.75242C14.3497 4.82339 14.5572 3.49902 13.4305 2.55022C12.5114 1.76944 11.1574 1.75956 10.2184 2.53046C9.07198 3.46937 9.26964 4.78385 9.63533 5.72277L11.9974 11.2574C12.0765 11.4551 11.9876 11.6824 11.7899 11.7615C11.7405 11.7813 11.6911 11.7911 11.6416 11.7911L11.6318 11.7813Z'
        fill='white'
        stroke='white'
        strokeWidth='0.761421'
        strokeMiterlimit='10'
      />
      <path
        d='M5.69702 9.52197C4.05639 8.80048 3.24596 7.57495 3.39421 6.0628C3.55234 4.48147 4.80752 3.23617 6.38886 3.10768C7.90101 2.9792 9.11666 3.8094 9.81837 5.45992C9.89744 5.65758 9.81837 5.8849 9.61082 5.96397C9.41316 6.04303 9.18584 5.96397 9.10677 5.75642C8.71144 4.82738 7.93066 3.7501 6.44816 3.87858C5.24239 3.97742 4.28371 4.92622 4.16511 6.14187C4.01686 7.61448 5.08426 8.41503 6.00341 8.81037C6.20107 8.88943 6.29002 9.11675 6.21096 9.31442C6.15166 9.46267 6.00341 9.55162 5.85516 9.55162L5.69702 9.52197Z'
        fill='white'
        stroke='white'
        strokeWidth='0.761421'
        strokeMiterlimit='10'
      />
      <path
        d='M4.17015 15.2306C3.20158 15.2306 2.37138 14.8452 1.74873 14.0743C0.740635 12.8488 0.750518 11.0796 1.77838 9.87387C2.75683 8.71752 4.20968 8.44079 5.8602 9.11286C6.05787 9.19192 6.1567 9.41924 6.07763 9.61691C5.99857 9.81457 5.77125 9.91341 5.57358 9.83434C4.20968 9.29076 3.1324 9.46866 2.37138 10.3779C1.5906 11.2971 1.58072 12.6511 2.35162 13.59C3.29053 14.7365 4.59514 14.5388 5.53405 14.1731C5.73172 14.0941 5.95903 14.183 6.0381 14.3807C6.11717 14.5783 6.02822 14.8057 5.83055 14.8847C5.24743 15.112 4.69397 15.2208 4.18003 15.2208L4.17015 15.2306Z'
        fill='white'
        stroke='white'
        strokeWidth='0.761421'
        strokeMiterlimit='10'
      />
      <path
        d='M6.522 20.597C6.4034 20.597 6.29468 20.597 6.17608 20.5772C4.59475 20.4191 3.34945 19.1639 3.22096 17.5925C3.09248 16.0803 3.92268 14.8647 5.5732 14.1629C5.77086 14.0839 5.99818 14.1629 6.07725 14.3705C6.15631 14.5682 6.07725 14.7955 5.8697 14.8745C4.51568 15.4478 3.88315 16.3472 3.99186 17.5332C4.0907 18.7389 5.0395 19.6976 6.25515 19.8162C7.73765 19.9645 8.52831 18.8971 8.92365 17.9779C9.00271 17.7802 9.23003 17.6913 9.4277 17.7704C9.62536 17.8494 9.71431 18.0767 9.63525 18.2744L9.63525 18.2843C8.97306 19.7964 7.87601 20.6069 6.522 20.6069V20.597Z'
        fill='white'
        stroke='white'
        strokeWidth='0.761421'
        strokeMiterlimit='10'
      />
      <path
        d='M11.8095 22.8012C11.0583 22.8012 10.3072 22.5442 9.69443 22.0303C8.53808 21.0518 8.26135 19.6089 8.93341 17.9485C9.01248 17.7508 9.2398 17.652 9.43746 17.731C9.63513 17.8101 9.73396 18.0374 9.6549 18.2351C9.11131 19.599 9.28921 20.6763 10.1985 21.4373C11.1176 22.2181 12.4716 22.228 13.4106 21.4571C14.557 20.5181 14.3594 19.2037 13.9937 18.2647L11.8391 12.5225C11.76 12.3249 11.849 12.0975 12.0467 12.0185C12.2443 11.9394 12.4716 12.0284 12.5507 12.226L14.7053 17.9781C15.3576 19.6484 15.0611 21.0914 13.8948 22.0599C13.2821 22.564 12.5507 22.8111 11.8095 22.8111V22.8012Z'
        fill='white'
        stroke='white'
        strokeWidth='0.761421'
        strokeMiterlimit='10'
      />
      <path
        d='M11.6333 11.7814L9.21191 3.99329L11.7124 1.78931L14.2821 3.03461L14.4105 5.94031L11.6333 11.7814Z'
        fill='white'
        stroke='white'
        strokeWidth='0.761421'
        strokeMiterlimit='10'
      />
    </svg>
  )
}
