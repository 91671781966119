import { ReactNode } from 'react'
import Link from 'next/link'
import { Box, Button, Typography } from '@mui/material'
import styled from '@emotion/styled'

import { functionalColor, textColor, fontWeight } from '@/utils/themeConfigs/customTheme'
import { mediaQuery } from '@/utils/helpers/breakpoint'

const LinkButton = styled(Button)`
  color: ${textColor.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  width: 100%;
  height: 100%;
  opacity: 50%;
`

const CustomButton = styled(Button)`
  color: ${textColor.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  width: 100%;
  height: 100%;
  opacity: 50%;
`

const ItemBox = styled(Box)`
  color: ${textColor.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: ${functionalColor.translucentWhite};
  opacity: 100%;
`

const IconContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ItemText = styled(Typography)`
  font-weight: ${fontWeight.bold};
  font-family: Montserrat, sans-serif;
  font-size: 0.75rem;
  line-height: 1.58;
  color: ${textColor.white};
  width: 60px;
  white-space: pre-wrap;
  word-break: keep-all;
  ${mediaQuery('tab')} {
    font-size: 0.625rem;
  }
`

type Props = {
  icon: JSX.Element
  href: string
  disabled?: boolean
  isButton?: boolean
  children?: ReactNode
}

export const NavigationBarItem = ({
  icon,
  href,
  disabled = false,
  isButton = false,
  children,
}: Props) => {
  return disabled ? (
    isButton ? (
      <CustomButton>
        <IconContainer>{icon}</IconContainer>
        <ItemText align='center'>{children}</ItemText>
      </CustomButton>
    ) : (
      <ItemBox>
        <IconContainer>{icon}</IconContainer>
        <ItemText align='center'>{children}</ItemText>
      </ItemBox>
    )
  ) : (
    <Link href={href}>
      <LinkButton>
        <IconContainer>{icon}</IconContainer>
        <ItemText align='center'>{children}</ItemText>
      </LinkButton>
    </Link>
  )
}
