import styled from '@emotion/styled'
import { Box, BoxProps, IconButton, Typography } from '@mui/material'

import { CloseIcon } from '@/components/icon/CloseIcon'
import { ApplicationLogo } from '@/components/icon/application-logo'
import { HamburgerMenuIcon } from '@/components/icon/HamburgerMenuIcon'
import { bgColor, outlineColor } from '@/utils/themeConfigs/customTheme'
import { mediaQuery } from '@/utils/helpers/breakpoint'
import { useIsMobileOrTablet } from '@/utils/hooks/useIsMobileOrTablet'

const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px;
  height: 100%;
  border-style: solid;
  border-color: ${outlineColor.lightGray};
  padding: 0 32px;
  ${mediaQuery('tab')} {
    flex-direction: row-reverse;
    padding: 16px;
    border-bottom: 0;
    width: 100%;
  }
`

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
`

const TitleText = styled(Typography)`
  ${mediaQuery('tab')} {
    font-size: 1.25rem;
  }
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.448;
`

const MenuButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  padding: 12px;
`

const HamburgerMenuContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})<MobileHistoryProps>`
  width: 100dvw;
  height: calc(100dvh - 72px - 64px);
  position: absolute;
  top: 72px;
  left: 0;
  background-color: ${bgColor.lightGreen};
  z-index: 999;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`

export type MobileMenuType = {
  content: React.ReactElement
  isMenuOpen: boolean
  openMenu: () => void
  closeMenu: () => void
}

type MobileHistoryProps = BoxProps & {
  isOpen?: boolean
}

type Props = {
  title: string
  mobileButton?: React.ReactElement
  mobileMenu?: MobileMenuType
}

export const Header = ({ title, mobileButton, mobileMenu }: Props) => {
  const toggleChatHistory = () => {
    if (mobileMenu) {
      mobileMenu.isMenuOpen ? mobileMenu.closeMenu() : mobileMenu.openMenu()
    }
  }

  const isMobileOrTablet = useIsMobileOrTablet()

  return (
    isMobileOrTablet !== undefined && (
      <Container>
        {isMobileOrTablet && mobileMenu ? (
          <MenuButton onClick={toggleChatHistory}>
            {mobileMenu.isMenuOpen ? <CloseIcon /> : <HamburgerMenuIcon />}
          </MenuButton>
        ) : (
          isMobileOrTablet && <Box />
        )}
        <TitleContainer>
          {isMobileOrTablet && <ApplicationLogo size={28} />}
          <TitleText>{title}</TitleText>
        </TitleContainer>
        <Box>{mobileButton}</Box>
        {mobileMenu && (
          <HamburgerMenuContent isOpen={mobileMenu.isMenuOpen}>
            {mobileMenu.content}
          </HamburgerMenuContent>
        )}
      </Container>
    )
  )
}
