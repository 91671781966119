import styled from '@emotion/styled'
import { Button } from '@mui/material'
import { VariantTypeProps } from '@/utils/types/common'
import { mainColor, bgColor, textColor } from '@/utils/themeConfigs/customTheme'
type BasicButtonProps = {
  label: string
  icon?: React.ReactElement
  variant?: VariantTypeProps
  width?: string
  maxWidth?: string
  height?: string
  type?: 'submit' | 'button'
  onClick?: () => void
  disabled?: boolean
  color?: string
  radius?: string
}

const getBackgroundColor = (variant?: VariantTypeProps, customColor?: string) => {
  if (variant === 'outlined') {
    return `${bgColor.white}`
  }
  return `${customColor ?? mainColor.user}`
}

const getHoverBackgroundColor = (variant?: VariantTypeProps, customColor?: string) => {
  if (variant === 'outlined') {
    return `${customColor ?? mainColor.user}`
  }
  return `${bgColor.white}`
}

const getHoverTextColor = (variant?: VariantTypeProps, customColor?: string) => {
  if (variant === 'outlined') {
    return `${bgColor.white}`
  }
  return `${customColor ?? mainColor.user}`
}

const CustomMuiButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'customColor' && prop !== 'customRadius' && prop !== 'maxWidth',
})<{
  width: string
  maxWidth: string
  height: string
  disabled: boolean
  icon?: React.ReactElement
  variant?: VariantTypeProps
  customColor?: string
  customRadius?: string
}>`
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height};
  border: ${({ disabled, customColor }) =>
    !disabled && `solid 1px  ${customColor ?? mainColor.user}`};
  border-radius: ${({ customRadius }) => customRadius ?? '24px'};
  font-weight: 700;
  background-color: ${({ variant, customColor }) =>
    getBackgroundColor(variant, customColor)};
  color: ${({ variant, customColor }) =>
    variant === 'outlined' ? `${customColor ?? mainColor.user}` : `${textColor.white}`};
  box-shadow: none;
  &:active {
    background-color: ${({ variant, customColor }) =>
      getHoverBackgroundColor(variant, customColor)};
    color: ${({ variant, customColor }) => getHoverTextColor(variant, customColor)};
    border: solid 1px ${({ customColor }) => customColor ?? mainColor.user};
    box-shadow: none;
  }
  &:hover {
    background-color: ${({ variant, customColor }) =>
      getBackgroundColor(variant, customColor)};
    color: ${({ variant, customColor }) =>
      variant === 'outlined' ? `${customColor ?? mainColor.user}` : `${textColor.white}`};
    box-shadow: none;
    border: solid 1px ${({ customColor }) => customColor ?? mainColor.user};
  }
`

export const BasicButton = ({
  label,
  icon,
  variant = 'contained',
  width = '104px',
  maxWidth = '100%',
  height = '47px',
  type = 'button',
  onClick,
  disabled = false,
  color,
  radius,
}: BasicButtonProps) => {
  return (
    <CustomMuiButton
      startIcon={icon}
      type={type}
      width={width}
      maxWidth={maxWidth}
      height={height}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      customColor={color}
      customRadius={radius}
    >
      {label}
    </CustomMuiButton>
  )
}
