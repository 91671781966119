import { Box } from '@mui/material'
import styled from '@emotion/styled'
import { usePathname } from 'next/navigation'

import { Header, MobileMenuType } from '@/components/layout/Header/Header'
import { mediaQuery } from '@/utils/helpers/breakpoint'
import { useIsMobileOrTablet } from '@/utils/hooks/useIsMobileOrTablet'
import { useAuthContext } from '@/context/AuthContext'

import { SideMenu } from '../SideMenu/SideMenu'
import { NavigationBar } from '../NavigationBar/NavigationBar'

const Container = styled(Box)`
  display: flex;
  height: 100svh;
  ${mediaQuery('tab')} {
    flex-direction: column;
    padding-left: 0;
  }
`

const SideMenuSpacer = styled(Box)`
  padding: 32px 0 32px 0;
`

const Content = styled(Box)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
`
const Wrap = styled(Box)`
  display: flex;
`

const ContentHeader = styled(Box)`
  flex-shrink: 0;
  width: 100%;
  background-color: #fff;
  height: 72px;
  ${mediaQuery('tab')} {
    position: fixed;
    z-index: 999;
  }
`

const ContentMain = styled(Box)`
  flex-grow: 1;
  min-height: 0;
  overflow: auto;
  ${mediaQuery('tab')} {
    padding: 0 16px;
    margin: 72px 0 64px;
  }
`

type Props = {
  headerTitle: string
  children: React.ReactElement
  sideMenuContent?: React.ReactElement
  mobileButton?: React.ReactElement
  mobileMenu?: MobileMenuType
}

export const Layout = ({
  children,
  sideMenuContent,
  headerTitle,
  mobileButton,
  mobileMenu,
}: Props) => {
  const isMobileOrTablet = useIsMobileOrTablet()
  const pathName = usePathname()
  const isManagementPortal = pathName.includes('admin')
  const { isAdmin } = useAuthContext()

  return (
    isMobileOrTablet !== undefined && (
      <Container>
        <Wrap>
          <NavigationBar isAdmin={isAdmin} isManagementPortal={isManagementPortal} />
          {sideMenuContent && !isMobileOrTablet && (
            <>
              <SideMenu>{sideMenuContent}</SideMenu>
              <SideMenuSpacer />
            </>
          )}
        </Wrap>

        <Content>
          <ContentHeader component='header'>
            <Header
              title={headerTitle}
              mobileButton={mobileButton}
              mobileMenu={mobileMenu}
            />
          </ContentHeader>
          <ContentMain component='main'>{children}</ContentMain>
        </Content>
      </Container>
    )
  )
}
